!<template>
  <div :class="{ licenseTeacher: dialogRegister }">
    <div class="about text-center" style="min-height: 500px;" v-if="LoadPage">
      <v-img src="../../assets/admin/wait.png" max-width="68%" min-height="500px" class="mx-auto my-3">
        <div style="position: absolute; bottom: 10px; left: 50%;transform: translate(-50%, -10%);">
          <h1>Please Wait</h1>
          <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </div>

      </v-img>
    </div>
    <div v-if="PanelStudentCode" class="license">
      <div style="min-height: 400px; position:relative">
        <p style="position:absolute; top: 17%; left:5%" class="text-h5">Enter the code you created in Lee Lee TE.</p>
        <div class="box-code">
          <v-alert dense outlined type="error" v-if="errorMessage != ''">
            {{ errorMessage }}
          </v-alert>
          <v-row>
            <p class="mx-auto text-h4 pt-3">Class Code:</p>
          </v-row>
          <v-row class="px-5">
            <v-text-field single-line filled outlined v-model="ClassCode" @keyup="uppercase"></v-text-field>
          </v-row>
          <div>
            <v-btn x-large depressed :disabled="loadSearchCode" :loading="loadSearchCode" color="primary"
              @click="SearchCode">
              Enter
            </v-btn>
          </div>
        </div>
        <p style="position:absolute; bottom:0; left:5%" class="text-h6">* If you do not have a class code created, you
          need a active license for access.</p>
      </div>
    </div>
    <div class="card-center" v-if="PanelTeacherRegister">
        <div class="manager-textbox">
          <img  style="width: 300px;" src="../../assets/homepage/leelee_te.png" class="manager-portrait" alt="Lee Lee">
          <div class="manager-text">
            <v-row justify="center">
              <v-col class="text-center text-h5 font-weight-bold">WELCOME</v-col>
            </v-row>
            <v-row class="mx-6">
              <v-col cols="12" class="pl-4"> Lee Lee Teacher Edition is a Spanish literacy tool for bilingual educators.</v-col>
              <v-col cols="5" class="pl-4 text-h6">
                Name:
              </v-col>
              <v-col cols="7" class="px-4">
                {{TeacherData.firstName }} {{ TeacherData.lastName }}
              </v-col>
              <v-col cols="5" class="pl-4 pt-0 text-h6">
                Email:
              </v-col>
              <v-col cols="7" class="px-4 pt-0">
                {{ TeacherData.email }}
              </v-col>
              <v-col cols="5" class="pl-4 text-h6" v-if="ShowAskBilungual">
                Bilingual teacher:
              </v-col>
              <v-col cols="7" class="py-0" v-if="ShowAskBilungual">
                <v-select :items="['Yes', 'No']" item-text="name" class="mx-2" return-object  solo
                    v-model="BilingueSelected" ></v-select>
              </v-col>
              <v-col cols="5" class="pl-4 text-h6">
                School:
              </v-col>
              <!-- <v-col cols="7" class="py-0">
                <v-autocomplete
                  :items="itemsEscuelas" item-text="name" class="mx-2" return-object label="School" solo
                  v-model="SchoolSelected" @change="VerificarOpciones"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" class="pl-4 text-h6" v-if="ShowAskGrade">
                Grade:
              </v-col>
              <v-col cols="7" class="py-0" v-if="ShowAskGrade">
                <v-select :items="itemsGrados" label="Grade" class="mx-2" solo v-model="GradeSelected" @change="VerificarOpciones"></v-select>
              </v-col> -->
            </v-row>
            <!-- <v-row justify="center">
              <v-col cols="6">
                <v-checkbox v-model="CheckboxLicense" @change="VerificarOpciones">
                  <template v-slot:label>
                    <div>
                      I agree to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://claseleelee.com/license-terms-use/"
                            @click.stop
                            v-on="on"
                          >
                          License and Terms of use
                          </a>
                        </template>
                        Check in web
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="isAvailable == 'no'">
              <v-col>
                <v-alert color="red darken-1" dark dense icon="mdi-school" prominent class="text-center text-subtitle-1">
                You need to acquire a Lee Lee Teacher Edition license to access.
              </v-alert>
              </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                  <v-btn :loading="loadingRegister" :disabled="disabledRegister" tile color="success" x-large @click="CreateTeacher">
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Register
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn color="error" class=" white--text" x-large dark href="https://claseleelee.com/">
                    Cancel
                    <v-icon right dark>
                      mdi-close-thick
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row> -->
          </div>
        </div>       
      </div>
  </div>
</template>
<script>
import { functions, auth, db } from '../../plugins/firebase'
export default {
  data() {
        return {
           token:'', 
           PanelStudentCode: false,
           errorMessage: "",
           ClassCode: "",
           loadSearchCode: false,
           dialogRegister: false,
           LoadPage: true,
           PanelTeacherRegister: false, 
           TeacherData:{
            firstName:"",
            lastName:"",
            email: ""
           },
           StudentData : {},
           ShowAskBilungual: true,
           BilingueSelected: "No",
        }
    },
    created(){
    },
    destroyed() {

    },
    async mounted() {
      this.Consultas();
    },
    methods: {
      async Consultas(){ 
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        let codeClasslink = this.$route.query.code;
        const loginClever = functions.httpsCallable('loginClasslinkDemo') // loginClasslink    --- loginClasslinkDemo
        let res = await loginClever({ code: codeClasslink }) 
        console.log(res) 
        this.LoadPage = false;
        if (res.data.Role == "Student") {
          if (res.data.TokenLogin == "") {// creamos
            this.PanelStudentCode = true;
            this.StudentData = res.data.idUserClever;
          }else{// logueamos
            let userCredential = await auth().signInWithCustomToken(res.data.TokenLogin)
            console.log(userCredential);
            this.LoginStoreCleverStudent(false);
          }
        }
        if (res.data.Role == "Teacher") {
          if (res.data.TokenLogin == "") { // si no tiene token sigca que no tenemos registrado al usuario
            // console.log(res.data.idUserClever);
            this.PanelTeacherRegister = true;
            this.TeacherData.firstName = res.data.idUserClever.FirstName
            this.TeacherData.lastName = res.data.idUserClever.LastName
            this.TeacherData.email = res.data.idUserClever.Email
          } else {
            if (res.data.IsCreated) {
              // console.log("entro a crear");
              auth().signInWithCustomToken(res.data.TokenLogin)
                  .then((userCredential) => {
                    console.log(userCredential);
                    userCredential.user.updateEmail(res.data.idUserClever.Email).then(()=>{
                      this.LoginStoreClever(res.data.IsCreated)
                      
                    }); 
                  });            
            }else{
              // console.log("entro logueandose");
              auth().signInWithCustomToken(res.data.TokenLogin)
                .then((userCredential) => {
                console.log("entro sig in");
                  this.LoginStoreClever(res.data.IsCreated)
                });
            }
          }
        }
      },
      async SearchCode() {
        this.loadSearchCode = true;
        console.log(this.StudentData);
        let Email ="";
        if (Object.prototype.hasOwnProperty.call(this.StudentData, 'Email')) {
          Email = this.StudentData.Email;
        }
        const CreateStudentCodeClassLink = functions.httpsCallable('CreateStudentCodeClassLink')
        let respues = await CreateStudentCodeClassLink({ uid: this.StudentData.LoginId, firstName: this.StudentData.FirstName, lastName: this.StudentData.LastName, code: this.ClassCode, email:Email })
          console.log(respues)
          //console.log('termino de crear');
          if (respues.data.answer) {

            let userCredential = await auth().signInWithCustomToken(respues.data.token)
            console.log(userCredential);
            if (Object.prototype.hasOwnProperty.call(this.StudentData, "Email")) {
              await userCredential.user.updateEmail(this.StudentData.Email)
            }
            this.LoginStoreCleverStudent(true);
          } else {
            this.errorMessage = 'error code'
            setTimeout(() => this.errorMessage = '', 4000);
            this.loadSearchCode = false;
          }
      },
      uppercase() {
        this.ClassCode = this.ClassCode.toUpperCase();
      },
      LoginStoreClever(nuevo) {
        this.$store.dispatch('loginClassLink', nuevo).then(() => {
          // localStorage.setItem('role', 'schoolTeacher')
          this.$router.push({ path: '/schoolTeacher' })
          
        }).catch(error => {
          console.log(error);
          this.errorMessage = error;
        });
      },
      LoginStoreCleverStudent(nuevo) {
        this.$store.dispatch('loginClassLinkStudent', nuevo).then(() => {
          // localStorage.setItem('role', 'schoolTeacher')
          this.$router.push({ path: '/student' })
          
        }).catch(error => {
          console.log(error);
          this.errorMessage = error;
        });
      },
    },
}
</script>
<style scoped>
  .licenseTeacher {
    background-image: url('../../assets/admin/FondoLetras.png');
    background-position: center;
    background-repeat: no-repeat, repeat-y;
    background-size: cover;
    min-height: 800px;
  }
  .license {
    background-image: url('../../assets/admin/code.png');
    background-position: center;
    background-repeat: no-repeat, repeat-y;
    background-size: cover;
    min-height: 450px;
  }
  .box-code {
    background-color: white;
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 10px;
    text-align: center;
    max-width: 650px;
    width: 550px;
    position: absolute;
    bottom: 70px;
    left: 5%;
  }
  .card-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .manager-textbox {
    background-color: #f4f6f9;
    position: relative;
    margin-top: 70px;
    max-width: 850px;
    border-radius: 50px;
  }

  .manager-text {
    margin-top: 40px;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .manager-portrait {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>